import { service } from '@ember/service';
import Component from '@glimmer/component';
import type UserModel from 'tio-common/models/user';
import type FeaturesService from 'tio-common/services/features';
import DashboardGridLayout from 'tio-employee/components/dashboard/grid-layout';
import DashboardAddLoan from 'tio-employee/components/dashboard/widgets/add-loan';
import DashboardCollegeCostCalculator from 'tio-employee/components/dashboard/widgets/college-cost-calculator';
import DashboardCommonQuestions from 'tio-employee/components/dashboard/widgets/common-questions';
import DashboardConsolidationRefinance from 'tio-employee/components/dashboard/widgets/consolidation-refinance';
import DashboardEmployeeAgent from 'tio-employee/components/dashboard/widgets/employee-agent';
import DashboardFiveTwoNineTools from 'tio-employee/components/dashboard/widgets/five-two-nine-tools';
import DashboardGlossary from 'tio-employee/components/dashboard/widgets/glossary';
import DashboardIncomeDrivenRepayment from 'tio-employee/components/dashboard/widgets/income-driven-repayment';
import DashboardInviteFamily from 'tio-employee/components/dashboard/widgets/invite-family';
import DashboardLoanForgiveness from 'tio-employee/components/dashboard/widgets/loan-forgiveness';
import DashboardLoanSummary from 'tio-employee/components/dashboard/widgets/loan-summary';
import DashboardMentoring from 'tio-employee/components/dashboard/widgets/mentoring';
import DashboardPayingForCollege from 'tio-employee/components/dashboard/widgets/paying-for-college';
import DashboardPublicServiceLoanForgiveness from 'tio-employee/components/dashboard/widgets/public-service-loan-forgiveness';
import DashboardReimbursement from 'tio-employee/components/dashboard/widgets/reimbursement';
import DashboardRepaymentPlans from 'tio-employee/components/dashboard/widgets/repayment-plans';
import DashboardScholarship from 'tio-employee/components/dashboard/widgets/scholarship';
import DashboardSecureYourFuture from 'tio-employee/components/dashboard/widgets/secure-your-future';
import DashboardStarbucksScap from 'tio-employee/components/dashboard/widgets/starbucks-scap';
import DashboardStrategyFinder from 'tio-employee/components/dashboard/widgets/strategy-finder';
import DashboardStudentLoanCoaches from 'tio-employee/components/dashboard/widgets/student-loan-coaches';
import DashboardStudentLoanRepayment from 'tio-employee/components/dashboard/widgets/student-loan-repayment';
import DashboardTuitionAssistance from 'tio-employee/components/dashboard/widgets/tuition-assistance';
import DashboardPublicServiceLoanForgivenessAdmin from 'tio-employee/components/dashboard/widgets/public-service-loan-forgiveness-admin';
import DashboardTuitionAssistanceAdmin from 'tio-employee/components/dashboard/widgets/tuition-assistance-admin';
import type EmployeeService from 'tio-employee/services/employee';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import TioAlert from 'tio-common/components/tio/alert';
import { t } from 'ember-intl';

export interface S {
  Args: {
    model: UserModel;
  };
}

// Until we deprecate the Vue app completely, this dashboard route
// can flash while the admin experience loads after selecting admin role.
// This conditional prevents that UI flash.
export default class DashboardRoute extends Component<S> {
  @service declare partner: PartnerService;
  @service declare sessionContext: SessionContextService;
  @service declare employee: EmployeeService;
  @service declare features: FeaturesService;

  model!: unknown;

  get hasAccounts() {
    return !!this.sessionContext.user?.person?.accounts?.length;
  }

  get needsToLinkLoans() {
    return !!this.sessionContext.user?.person && !this.hasAccounts;
  }

  get isFamilyMemberView() {
    return this.sessionContext.isFamilyMember;
  }

  get isPslfEnabled() {
    return this.partner.isPslfEnabled;
  }

  get isFaqEnabled() {
    return this.partner.isFaqEnabled;
  }

  get hasContributions() {
    return this.employee.hasContributions;
  }

  get isFamilyPlanEnabled() {
    return this.partner.doesAllowFamilyPlan;
  }

  get isTaMentoringEnabled() {
    return this.partner.companySettings?.tuitionAssistance?.isTaMentoringEnabled;
  }

  get isEnabledNewTuitionAssistance() {
    if (this.isTaEnabledSettingExists) {
      return (
        this.features.isEnabled('tuitionAssistance') &&
        this.partner.hasNewTuitionAssistance &&
        this.isTaSettingEnabled
      );
    } else {
      return this.features.isEnabled('tuitionAssistance') && this.partner.hasNewTuitionAssistance;
    }
  }

  get isTaEnabledSettingExists() {
    return this.partner.companySettings.tuitionAssistance &&
      'isTaEnabled' in this.partner.companySettings.tuitionAssistance
      ? true
      : false;
  }

  get isTaSettingEnabled() {
    return (
      this.partner.companySettings.tuitionAssistance &&
      !!this.partner.companySettings.tuitionAssistance.isTaEnabled
    );
  }

  get hasLegacyTuitionAssistance() {
    return this.employee.hasLegacyTuitionAssistance;
  }

  get isIdrEnabled() {
    return this.partner.isIdrEnabled;
  }

  get isSyfEnabled() {
    return this.partner.companySettings.modules?.syf?.isEnabled;
  }

  get isPrioritizedStrategyFinder() {
    // Prioritize SF widget for partners who are PSLF_ELIGIBLE but have not purchased PSLF product
    // TODO: isPslfEligible does not exist on service
    return this.partner.isPslfEligible && !this.isPslfEnabled;
  }

  get isDeprioritizedStrategyFinder() {
    return !this.isPrioritizedStrategyFinder;
  }

  get isContributionPlan() {
    return this.employee.current.plan && this.employee.current.plan.cadence !== 'NEVER';
  }

  get isSlrEnabled() {
    return this.isContributionPlan;
  }

  get isDashboardAddLoanEnabled() {
    return this.isContributionPlan ? false : this.needsToLinkLoans;
  }

  get isPslfAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'PSLF_ADMIN');
  }

  get isTAAdmin() {
    return this.sessionContext.roles?.some(
      (role) =>
        role.relationshipType.startsWith('TAS.Approver') ||
        role.relationshipType === 'TAS.Admin.SUPERUSER'
    );
  }

  get familyMemberWidgets() {
    return (
      [
        this.isDashboardAddLoanEnabled ? DashboardAddLoan : undefined,
        this.isEnabledNewTuitionAssistance ? DashboardTuitionAssistance : undefined,
        this.isTaMentoringEnabled ? DashboardMentoring : undefined,
        this.isPslfEnabled ? DashboardPublicServiceLoanForgiveness : undefined,
        this.isIdrEnabled ? DashboardIncomeDrivenRepayment : undefined,
        this.isPrioritizedStrategyFinder ? DashboardStrategyFinder : undefined,
        this.hasAccounts ? DashboardLoanSummary : undefined,
        this.partner.isStarbucks ? DashboardStarbucksScap : undefined,
        //DashboardLoanProjections,
        DashboardStudentLoanCoaches,
        DashboardLoanForgiveness,
        DashboardPayingForCollege,
        DashboardRepaymentPlans,
        DashboardConsolidationRefinance,
        DashboardScholarship,
        DashboardCollegeCostCalculator,
        DashboardFiveTwoNineTools,
        DashboardGlossary,
        this.hasLegacyTuitionAssistance ? DashboardReimbursement : undefined,
        this.isDeprioritizedStrategyFinder ? DashboardStrategyFinder : undefined,
        this.isFaqEnabled ? DashboardCommonQuestions : undefined,
      ].filter((val) => val !== undefined && val !== null) || []
    );
  }

  get sortedWidgets() {
    if (this.partner.isHoraceMann) {
      return this.horaceMannWidgets;
    }

    if (this.args.model.isTaOnly) {
      return [
        this.isTAAdmin ? DashboardTuitionAssistanceAdmin : undefined,
        this.isEnabledNewTuitionAssistance ? DashboardTuitionAssistance : undefined,
        this.isTaMentoringEnabled ? DashboardMentoring : undefined,
      ];
    }

    if (this.isFamilyMemberView) {
      return this.familyMemberWidgets;
    }

    return (
      [
        this.isTAAdmin ? DashboardTuitionAssistanceAdmin : undefined,
        this.isPslfAdmin ? DashboardPublicServiceLoanForgivenessAdmin : undefined,
        this.isDashboardAddLoanEnabled ? DashboardAddLoan : undefined,
        this.isEnabledNewTuitionAssistance ? DashboardTuitionAssistance : undefined,
        this.isSyfEnabled ? DashboardSecureYourFuture : undefined,
        this.isTaMentoringEnabled ? DashboardMentoring : undefined,
        this.isPslfEnabled ? DashboardPublicServiceLoanForgiveness : undefined,
        this.isSlrEnabled ? DashboardStudentLoanRepayment : undefined,
        this.isIdrEnabled ? DashboardIncomeDrivenRepayment : undefined,
        this.isPrioritizedStrategyFinder ? DashboardStrategyFinder : undefined,
        this.hasAccounts ? DashboardLoanSummary : undefined,
        this.partner.isStarbucks ? DashboardStarbucksScap : undefined,
        //DashboardLoanProjections,
        DashboardStudentLoanCoaches,
        DashboardLoanForgiveness,
        // 'DebtCancellation',
        DashboardPayingForCollege,
        DashboardRepaymentPlans,
        DashboardConsolidationRefinance,
        this.isFamilyPlanEnabled ? DashboardInviteFamily : undefined,
        DashboardScholarship,
        DashboardCollegeCostCalculator,
        DashboardFiveTwoNineTools,
        DashboardGlossary,
        this.hasLegacyTuitionAssistance ? DashboardReimbursement : undefined,
        this.isDeprioritizedStrategyFinder ? DashboardStrategyFinder : undefined,
        this.isFaqEnabled ? DashboardCommonQuestions : undefined,
      ].filter((val) => val !== undefined && val !== null) || []
    );
  }

  get horaceMannWidgets() {
    return (
      [
        this.isPslfEnabled ? DashboardPublicServiceLoanForgiveness : undefined,
        this.isDashboardAddLoanEnabled ? DashboardAddLoan : undefined,
        this.hasAccounts ? DashboardLoanSummary : undefined,
        DashboardStrategyFinder,
        DashboardEmployeeAgent,
        DashboardStudentLoanCoaches,
        DashboardLoanForgiveness,
        // 'DebtCancellation',
        this.hasLegacyTuitionAssistance ? DashboardReimbursement : undefined, // Does HM even offer Tuition Assitance?
      ].filter((val) => val !== undefined && val !== null) || []
    );
  }

  get taOnly() {
    return this.partner.isTaOnly;
  }

  <template>
    {{#if this.sessionContext.isEmployeeView}}
      {{! temporarily remove overflow-auto from dashboard.gts to parent component }}
      {{! When removing this banner, please add overflow-auto back }}
      <div class="flex flex-col gap-1 overflow-auto">
        {{#unless this.taOnly}}
          <TioAlert
            @type="info"
            @iconClass="text-blue-400"
            @allowDismiss={{false}}
            class="mt-4 mx-4 rounded-none"
          >
            <:body>
              {{t "dashboard_banner.doe_message" htmlSafe=true}}
            </:body>
          </TioAlert>
        {{/unless}}
        <DashboardGridLayout>
          {{! @glint-expect-error: return}}
          {{#each this.sortedWidgets as |widget|}}
            {{! @glint-expect-error: return}}
            {{#let (component widget) as |Widget|}}
              <Widget @user={{@model.user}} />
            {{/let}}
          {{/each}}
        </DashboardGridLayout>
      </div>
    {{/if}}
  </template>
}
