import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { or, not } from 'tio-ui/utilities';
import { hash, fn } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import type RouterService from '@ember/routing/router-service';
import { Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { Select } from 'tio-ui/components/forms';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { type IntlService } from 'ember-intl';
import { v4 as uuid } from 'uuid';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import Component from '@glimmer/component';
import Document from 'ember-static-heroicons/components/outline-24/document';
import Drawer from 'tio-ui/components/drawer';
import FileUploader from 'tio-ui/components/file-uploader';
import type { UploadFile, Queue } from 'ember-file-upload';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type ObservabilityService from 'tio-employee/services/observability';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type ObservablesService from 'tio-common/services/observables';
import type PersonModel from 'tio-common/models/person';
import { registerDestructor } from '@ember/destroyable';
import type RemoteEventLoggerService from 'tio-employee/services/remote-event-logger';
import UploadProgress from 'tio-employee/components/observability/upload-progress';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import type { ObservableSource } from 'tio-employee/services/observability';
import ObservabilityUploadController from 'tio-employee/controllers/authenticated/observability/upload';
import {
  getObservableProductRoute,
  getInstitutionMatch,
} from 'tio-employee/services/observability';
import type { ProviderParam } from 'tio-employee/services/observability';
import { Header, Section, VStack, HStack } from 'tio-ui/components/layout';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import type Owner from '@ember/owner';

// wait 10s before telling users to wait for an email
const PROCESSING_TIMEOUT = 10000;
const DEFAULT_PREFERRED_PROVIDER = 'statement';

const providerCtaTranslation = (provider: ProviderParam) => {
  switch (provider) {
    case 'statement':
      return 'observability.uploads.statement.cta';
    case 'nslds':
      return 'observability.uploads.nslds.cta';
    case 'transaction_history':
      return 'observability.uploads.transaction_history.cta';
  }
};

const providerPromptTranslation = (provider: ProviderParam) => {
  switch (provider) {
    case 'statement':
      return 'observability.uploads.statement.prompt';
    case 'nslds':
      return 'observability.uploads.nslds.prompt';
    case 'transaction_history':
      return 'observability.uploads.transaction_history.prompt';
  }
};

const documentTypeNameTranslation = (provider?: ProviderParam) => {
  switch (provider) {
    case 'statement':
      return 'observability.statement';
    case 'nslds':
      return 'observability.nslds';
    case 'transaction_history':
      return 'observability.transaction_history';
    default:
      return 'observability.default_document';
  }
};

const drawerHeaderTranslation = (provider?: ProviderParam) => {
  switch (provider) {
    case 'statement':
      return 'observability.uploads.statement.prompt';
    case 'nslds':
      return 'observability.uploads.nslds.prompt';
    case 'transaction_history':
      return 'observability.uploads.transaction_history.prompt';
    default:
      return 'observability.uploads.statement.prompt';
  }
};

const drawerInstructionsTranslation = (provider?: ProviderParam) => {
  switch (provider) {
    case 'statement':
      return 'observability.uploads.statement.instructions';
    case 'nslds':
      return 'observability.uploads.nslds.instructions';
    case 'transaction_history':
      return 'observability.uploads.transaction_history.instructions';
    default:
      return 'observability.uploads.statement.instructions';
  }
};

interface ObservabilityUploadRouteSignature {
  Args: {
    controller: ObservabilityUploadController;
    model: {
      person: PersonModel;
      financialInstitutions: FinancialInstitutionModel[];
    };
  };
}

export default class ObservabilityUploadRoute extends Component<ObservabilityUploadRouteSignature> {
  @service declare router: RouterService;
  @service declare observability: ObservabilityService;
  @service declare observables: ObservablesService;
  @service declare fileQueue: Queue;
  @service declare intl: IntlService;
  @service declare remoteEventLogger: RemoteEventLoggerService;

  @tracked batchId?: string;
  @tracked instructionsOpen: boolean = false;
  @tracked instructionsType?: 'nslds' | 'statement' | 'transaction_history';
  // this can be an incomplete mock financial institution in order to hit the translation
  // select clause default case when the name doesn't match; see servicerOptions getter's
  // return value with mock servicer "General Instructions" spread into it
  @tracked financialInstitution?: FinancialInstitutionModel | { name: string };
  @tracked upload?: UploadFile;
  @tracked observableDocument?: ObservableDocumentModel;
  subscription:
    | ReturnType<typeof this.observability.cable.consumer>['subscriptions']['create']
    | null = null;
  @tracked uploading = false;
  @tracked processing = false;
  @tracked uploadError?: Error;
  @tracked uploadTimeout?: ReturnType<typeof setTimeout>;
  @tracked timedOut = false;

  constructor(owner: Owner, args: ObservabilityUploadRouteSignature['Args']) {
    super(owner, args);
    this.initializeBatchId();

    // cleanup upload timeout on unmount
    registerDestructor(this, () => {
      clearTimeout(this.uploadTimeout);
      if (this.batchId) {
        this.observability.unsubscribeFromBatchStatus(this.batchId);
      }
    });
  }

  /***************************/
  /* Accessors               */
  /***************************/

  get providers(): ProviderParam[] {
    const matches = this.args.controller.providers.split(',').filter(Boolean);
    return <ProviderParam[]>matches;
  }

  get preferredProvider(): ProviderParam {
    const match = this.args.controller.providers.split(',').filter(Boolean).at(0);
    return <ProviderParam>match || DEFAULT_PREFERRED_PROVIDER;
  }

  get alternateProvider(): ProviderParam | undefined {
    const match = this.args.controller.providers.split(',').filter(Boolean).at(1);
    if (match) return <ProviderParam>match;
  }

  get productSource(): ObservableSource {
    return <ObservableSource>this.args.controller.source;
  }

  get optionalType(): 'nslds' | 'statement' {
    return this.preferredProvider === 'statement' ? 'nslds' : 'statement';
  }

  get servicerOptions() {
    const servicerName = this.intl.t('observability.uploads.placeholder_servicer');
    return [{ name: servicerName }, ...this.args.model.financialInstitutions];
  }

  get needsServicer() {
    return ['statement', 'transaction_history'].includes(<string>this.instructionsType);
  }

  get queue() {
    // @ts-expect-error "Property 'findOrCreate' does not existon type 'Queue'" - yes it does
    return this.fileQueue.findOrCreate('observable-assets');
  }

  /***************************/
  /* Actions                 */
  /***************************/

  @action openInstructions(provider: ProviderParam): void {
    this.instructionsType = provider;
    this.instructionsOpen = true;
  }

  @action
  closeInstructions(): void {
    this.instructionsOpen = false;
    this.instructionsType = undefined;
    this.financialInstitution = undefined;
  }

  @action
  setFinancialInstitution(name: string): void {
    this.financialInstitution = this.args.model.financialInstitutions.find(
      (institution: FinancialInstitutionModel) => institution.name === name
    ) || { name: 'default' };
  }

  @action
  initializeBatchId() {
    this.batchId = uuid();
  }

  @action
  async handleFileAdded(upload: UploadFile): Promise<void> {
    this.upload = upload;
  }

  @action
  removeFile() {
    this.queue.remove(this.upload);
    this.upload = undefined;
  }

  @action
  async handleSubmit(e: SubmitEvent) {
    e.preventDefault();
    const { person } = this.args.model;

    this.uploading = true;
    this.subscription = this.observability.subscribeToBatchStatus(
      this.batchId!,
      this.productSource
    );

    const batch = this.batchId;

    this.remoteEventLogger.logObservabilityEvent({
      eventName: 'UPLOAD_START',
      component: 'ObservabilityUploadRoute',
    });

    this.observables
      .createObservableDocument(this.upload!, { person, batch })
      .then((document) => {
        this.remoteEventLogger.logObservabilityEvent({
          eventName: 'UPLOAD_SUCCESS',
          component: 'ObservabilityUploadRoute',
          document,
        });
        this.observableDocument = document;
        this.uploadTimeout = setTimeout(() => (this.timedOut = true), PROCESSING_TIMEOUT);
        this.processing = true;
        this.uploading = false;
      })
      .catch((error) => {
        this.remoteEventLogger.logObservabilityEvent({
          eventName: 'UPLOAD_FAILURE',
          component: 'ObservabilityUploadRoute',
        });
        this.uploadError = error;
      });
  }

  @action
  reset() {
    clearTimeout(this.uploadTimeout);
    this.uploading = false;
    this.processing = false;
    this.queue.remove(this.upload);
    this.upload = undefined;
    this.uploadError = undefined;
  }

  <template>
    <VStack>
      <Header>
        <LinkTo
          @route={{getObservableProductRoute this.productSource}}
          class="flex items-center mb-4 text-gray-900 text-sm"
        >
          <ChevronLeft class="w-4 h-4" />
          {{t "back"}}
        </LinkTo>
        <p>{{t "observability.uploads.title" source=this.productSource}}</p>
      </Header>
      <p>{{t "observability.uploads.subtitle" source=this.productSource}}</p>
      <HStack>
        <Section>
          <:header>
            {{t "observability.uploads.form_section_title" source=this.productSource}}
          </:header>
          <:body>
            <form {{on "submit" this.handleSubmit}}>
              {{#if this.upload}}
                {{! min height to match file uploader }}
                <Section class="min-h-[11rem]">
                  <:header>
                    {{t "observability.uploads.form.document.selected"}}
                  </:header>
                  <:body>
                    <div class="flex items-center px-4 pt-4 text-tio-gray-600">
                      <Document class="w-6 mr-2" />
                      <span>{{this.upload.file.name}}</span>
                      <button class="ml-auto" type="button" {{on "click" this.removeFile}}>
                        <XCircle class="w-6" />
                      </button>
                    </div>
                  </:body>
                </Section>
              {{else}}
                <h3 class="tio-h3">{{t "observability.uploads.form.document.label"}}</h3>
                <FileUploader
                  @name="observable-assets"
                  @onFileAdded={{this.handleFileAdded}}
                  @useDropzone={{true}}
                >
                  <button type="submit">
                    <Document class="w-4 mr-2" />
                    {{t "observability.uploads.form.document.input"}}
                  </button>
                </FileUploader>
              {{/if}}
              <Button
                @intent="primary"
                type="submit"
                disabled={{not this.upload}}
                class="mt-4 *:justify-center w-full"
              >
                {{t "observability.uploads.form.submit"}}
              </Button>
            </form>
          </:body>
        </Section>
        <Section>
          <:header>
            {{t "observability.uploads.instructions_section_title" providers=this.providers.length}}
          </:header>
          <:body>
            <VStack @collapsed={{true}}>
              <Section>
                <:header>
                  <span class="block text-fuscia-300 text-sm mb-2">
                    {{t "observability.uploads.best_prefix"}}
                  </span>
                  {{t (providerPromptTranslation this.preferredProvider)}}
                </:header>
                <:body>
                  <ActionableTranslation
                    @t={{providerCtaTranslation this.preferredProvider}}
                    @params={{hash
                      documentType=(documentTypeNameTranslation this.preferredProvider)
                    }}
                    @actions={{hash
                      openInstructions=(hash
                        t="click_here" onClick=(fn this.openInstructions this.preferredProvider)
                      )
                    }}
                  />
                </:body>
              </Section>
              {{#if this.alternateProvider}}
                <Section>
                  <:header>
                    <span class="block text-gray-600 text-sm mb-2">
                      {{t "observability.uploads.optional_prefix"}}
                    </span>
                    {{t (providerPromptTranslation this.alternateProvider)}}
                  </:header>
                  <:body>
                    <ActionableTranslation
                      @t={{providerCtaTranslation this.alternateProvider}}
                      @params={{hash
                        documentType=(documentTypeNameTranslation this.alternateProvider)
                      }}
                      @actions={{hash
                        openInstructions=(hash
                          t="click_here" onClick=(fn this.openInstructions this.alternateProvider)
                        )
                      }}
                    />
                  </:body>
                </Section>
              {{/if}}
            </VStack>
          </:body>
        </Section>
      </HStack>
    </VStack>
    <Modal @isOpen={{or this.uploading this.processing}} @allowClosing={{false}} @size="xl" as |m|>
      <m.Header>{{t "observability.uploads.progress.header"}}</m.Header>
      <m.Body>
        {{#if this.upload}}
          <UploadProgress
            @observableDocument={{this.observableDocument}}
            @source={{this.productSource}}
            @upload={{this.upload}}
            @uploadError={{this.uploadError}}
            @onRetry={{this.reset}}
            @timedOut={{this.timedOut}}
            @providers={{this.providers}}
          />
        {{/if}}
      </m.Body>
    </Modal>
    <Drawer @isOpen={{this.instructionsOpen}} @onClose={{this.closeInstructions}} @size="lg">
      <VStack>
        <Header>{{t (drawerHeaderTranslation this.instructionsType)}}</Header>
        <VStack @collapsed={{true}}>
          {{#if this.needsServicer}}
            <span class="block font-semibold">
              {{t "observability.uploads.financial_institution.label"}}
            </span>
            <span class="block text-sm leading-none pt-4">
              {{t
                "observability.uploads.financial_institution.help"
                documentType=(t (documentTypeNameTranslation this.instructionsType))
              }}
            </span>
            <Select
              @items={{this.servicerOptions}}
              @allowEmpty={{true}}
              @onAction={{this.setFinancialInstitution}}
              @selectionMode="single"
              class="mt-4"
            >
              <:item as |i|>
                <i.Item @key={{i.item.name}}>{{i.item.name}}</i.Item>
              </:item>
            </Select>
            {{#if this.financialInstitution}}
              <article class="tio-prose">
                {{t
                  (drawerInstructionsTranslation this.instructionsType)
                  servicer=(getInstitutionMatch this.financialInstitution.name)
                  htmlSafe=true
                }}
              </article>
            {{/if}}
          {{else}}
            <article class="tio-prose">
              {{t
                (drawerInstructionsTranslation this.instructionsType)
                servicer=(getInstitutionMatch this.financialInstitution.name)
                htmlSafe=true
              }}
            </article>
          {{/if}}
        </VStack>
      </VStack>
    </Drawer>
  </template>
}
