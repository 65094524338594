import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AppHeader from 'tio-employee/components/app/header';
import AuthRegisterPostAuthTermsDialog from 'tio-employee/components/auth/register/post-auth-terms-dialog';
import AuthSessionExpiringDialog from 'tio-employee/components/auth/session-expiring-dialog';
import Component from '@glimmer/component';
import type { TOC } from '@ember/component/template-only';
import type EmployeeService from 'tio-employee/services/employee';
import type PartnerService from 'tio-employee/services/partner';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';

interface AuthentecatedRouteSignature {
  Args: {
    model: UserModel;
  };
  Blocks: {
    default: [];
  };
}

export default class AuthenticatedRouteComponent extends Component<AuthentecatedRouteSignature> {
  @service declare sessionContext: SessionContextService;
  @service declare store: StoreService;
  @service declare partner: PartnerService;
  @service declare employee: EmployeeService;
  @service declare features: FeaturesService;
  @service declare sideDrawer: SideDrawerService;

  @tracked isSidebarOpen = true;
  @tracked isFinishedRegisterTermsDialog = false;

  constructor(owner: Owner, args: AuthentecatedRouteSignature['Args']) {
    super(owner, args);
    this.sideDrawer.isOpen = true;
  }

  get isSidebarOpenHack() {
    // Bridge until old one is gone.
    // This is how the hamburger stack is able to open the sidebar.
    return this.sideDrawer.isOpen;
  }

  get taPartnerPaymentsReviewPageEnabled() {
    return this.features.isEnabled('taPartnerPaymentsReviewPage');
  }

  get isTaOnly() {
    return this.partner.isTaOnly || this.employee.isTaOnlyEmployee;
  }

  get hasTuitionAssistance() {
    return !!(this.partner.hasNewTuitionAssistance || this.employee.hasLegacyTuitionAssistance);
  }

  get isOwner() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'ACCOUNT_OWNER');
  }

  get isAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'TIO_ADMIN');
  }

  get isParticipant() {
    return !!(
      this.sessionContext.roles.find((e) => e.relationshipType === 'PARTICIPANT') && !this.isTaOnly
    );
  }

  get isEmployeeAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'EMPLOYEE_ADMIN');
  }

  get isPslfAdmin() {
    return !!this.sessionContext.roles.find((e) => e.relationshipType === 'PSLF_ADMIN');
  }

  get isPslfEnabled() {
    return !!this.partner.isPslfEnabled;
  }

  get isSyfEnabled() {
    return !!this.partner.isSyfEnabled;
  }

  get idrEnabled() {
    return this.partner.isIdrEnabled;
  }

  get isTasApprover() {
    return !!this.sessionContext.roles.find(
      (e) => e.relationshipType === 'TAS.Approver.SUPERVISOR'
    );
  }

  get isTaEnabledSettingExists() {
    return this.partner.companySettings.tuitionAssistance &&
      'isTaEnabled' in this.partner.companySettings.tuitionAssistance
      ? true
      : false;
  }

  get isTaSettingEnabled() {
    return (
      this.partner.companySettings.tuitionAssistance &&
      !!this.partner.companySettings.tuitionAssistance.isTaEnabled
    );
  }

  get showTuitionAssistance() {
    if (this.isTaEnabledSettingExists) {
      return !!(this.partner.hasNewTuitionAssistance && this.isTaSettingEnabled);
    } else {
      return this.hasTuitionAssistance;
    }
  }

  get showRegisterTermsDialog() {
    // Use a tracked property to allow for faster closing of the dialog
    // (i.e. better UX) before the sessionContext re-calcs `needsToAcceptTerms`
    if (this.isFinishedRegisterTermsDialog) {
      return false;
    }
    return this.sessionContext.needsToAcceptAppTerms;
  }

  get showSlr() {
    // check for contribution plan
    return this.employee.current.plan && this.employee.current.plan.cadence !== 'NEVER';
  }

  @action
  toggleSidebar() {
    this.sideDrawer.toggleDrawer();
  }

  @action
  async didCompleteSsoRegistration() {
    this.isFinishedRegisterTermsDialog = true;
    // Reload the user with the newly created usage agreement and privacy agreement
    await this.store.findRecord('user', this.sessionContext.user.id, {
      include: 'usage-agreement,privacy-agreement,custom-register-agreements',
      reload: true,
    });
  }

  <template>
    <div class="min-h-screen antialiased bg-gray-50 text-black">
      {{! Most examples use display none with a time, Removing pointer events
          allows css to just do its thing.
      }}
      <div
        class="relative z-50 lg:hidden {{unless this.isSidebarOpenHack 'pointer-events-none'}} "
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 transition-opacity ease-linear duration-300 bg-black/20
            {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
            "
        ></div>

        <div class="fixed inset-0 flex">
          <div
            class="relative mr-16 flex w-full max-w-xs flex-1 transition-transform ease-in-out duration-300
              {{if this.isSidebarOpenHack 'translate-x-0' '-translate-x-full'}}
              "
          >
            <div
              class="absolute left-full top-0 flex w-8 h-16 bg-midnight rounded-r-md mt-5 transition-opacity ease-linear duration-300
                {{if this.isSidebarOpenHack 'opacity-100' 'opacity-0'}}
                "
            >
              <button type="button" class="w-8 h-16" {{on "click" this.toggleSidebar}}>
                <span class="sr-only">{{t "sidebar.close"}}</span>
                <XMark class="h-6 w-6 text-white" />
              </button>
            </div>

            {{! Sidebar component, swap this element with another sidebar if you like }}
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-midnight px-6 pb-4">
              <AppSidebar
                @isTaOnly={{this.isTaOnly}}
                @showTuitionAssistance={{this.showTuitionAssistance}}
                @isParticipant={{this.isParticipant}}
                @showPslf={{this.isPslfEnabled}}
                @showSyf={{this.isSyfEnabled}}
                @showIdr={{this.idrEnabled}}
                @isPslfAdmin={{this.isPslfAdmin}}
                @isTasApprover={{this.isTasApprover}}
                @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
                @isEmployeeAdmin={{this.isEmployeeAdmin}}
                @isAdmin={{this.isAdmin}}
                @isOwner={{this.isOwner}}
                @showSlr={{this.showSlr}}
              />
            </div>
          </div>
        </div>
      </div>

      {{! Static sidebar for desktop }}
      <div class="hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-56 lg:flex-col">
        <div
          class="flex grow flex-col gap-y-3 overflow-y-auto border-r border-gray-200 bg-midnight px-6 pb-4"
        >
          <AppSidebar
            @isTaOnly={{this.isTaOnly}}
            @showTuitionAssistance={{this.showTuitionAssistance}}
            @isParticipant={{this.isParticipant}}
            @showPslf={{this.isPslfEnabled}}
            @showSyf={{this.isSyfEnabled}}
            @showIdr={{this.idrEnabled}}
            @isPslfAdmin={{this.isPslfAdmin}}
            @isTasApprover={{this.isTasApprover}}
            @isTasPartnerPaymentsEnabled={{this.taPartnerPaymentsReviewPageEnabled}}
            @isEmployeeAdmin={{this.isEmployeeAdmin}}
            @isAdmin={{this.isAdmin}}
            @isOwner={{this.isOwner}}
            @showSlr={{this.showSlr}}
          />
        </div>
      </div>

      <div class="lg:pl-56">
        <AppHeader />
        <main class="scroll-smooth max-w-7xl">
          <div class="px-0">
            {{outlet}}
          </div>
        </main>
      </div>
    </div>

    {{! Showing status handled by dialog component itself }}
    <AuthSessionExpiringDialog />

    {{#if this.showRegisterTermsDialog}}
      <AuthRegisterPostAuthTermsDialog
        @didSave={{this.didCompleteSsoRegistration}}
        @primaryEmailAddress={{@model.primaryEmail}}
      />
    {{/if}}
  </template>
}

import AcademicCap from 'ember-static-heroicons/components/outline-24/academic-cap';
import Home from 'ember-static-heroicons/components/outline-24/home';
import UserCircle from 'ember-static-heroicons/components/outline-24/user-circle';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import Lifebuoy from 'ember-static-heroicons/components/outline-24/lifebuoy';
import RocketLaunch from 'ember-static-heroicons/components/outline-24/rocket-launch';
import CurrencyDollar from 'ember-static-heroicons/components/outline-24/currency-dollar';
import BookOpen from 'ember-static-heroicons/components/outline-24/book-open';
import XMark from 'ember-static-heroicons/components/outline-24/x-mark';
import ArrowLeftOnRectangle from 'ember-static-heroicons/components/outline-24/arrow-left-on-rectangle';
import { and, or } from 'tio-ui/utilities';
import type FeaturesService from 'tio-common/services/features';
import Newspaper from 'ember-static-heroicons/components/outline-24/newspaper';
import TableCells from 'ember-static-heroicons/components/outline-24/table-cells';
import ArrowUpTray from 'ember-static-heroicons/components/outline-24/arrow-up-tray';
import type SideDrawerService from 'tio-common/services/side-drawer';
import type Owner from '@ember/owner';
import type RouterService from '@ember/routing/router';
import { Divider } from 'tio-ui/components/utilities';
/**
 * Represents a group of items within a sidebar that can be toggled to show or hide its children.
 * This component maintains its open/closed state and toggles it upon clicking the group label.
 *
 * @example
 * <SidebarItemGroup @icon="banknotes" @label="Accounting">
 *   <SidebarItem @route="authenticated.accounting.invoices.index" @label="Invoices" />
 * </SidebarItemGroup>
 *
 * @template
 * Consists of a button that toggles the visibility of a nested list containing child components,
 * which are rendered via the `default` block slot.
 *
 * @interface
 * Args:
 *  - icon?: Icons - Optional icon to display alongside the label.
 *  - label: string - The label text for the toggle button.
 * Blocks:
 *  - default: Component[] - The child components to display when the group is expanded.
 */

interface SidebarItemGroupSignature {
  Args: {
    icon?: unknown;
    label: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLLIElement;
}

export class SidebarItemGroup extends Component<SidebarItemGroupSignature> {
  @tracked isOpen = true;

  @action
  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  <template>
    <li ...attributes>
      <button
        type="button"
        class="hover:bg-gray-50 hover:text-midnight text-white -mx-2 flex items-start w-full text-left rounded-md p-2 gap-x-1 text-sm font-semibold"
        aria-controls="sub-menu-1"
        aria-expanded="false"
        {{on "click" this.toggleOpen}}
      >
        {{#if @icon}}
          {{! @glint-expect-error: need types on heroicons}}
          <@icon class="h-5 w-5 shrink-0 hover:text-midnight" />
        {{/if}}

        <p>{{@label}}</p>
        <ChevronRight
          class="ml-auto h-5 w-5 shrink-0
            {{if this.isOpen 'rotate-90 text-gray-500' 'text-gray-400'}}"
        />
      </button>
      <ul
        class="{{unless this.isOpen 'hidden'}}
          mt-1 px-2 [&_button]:py-2 [&_button]:pr-2 [&_button]:pl-6 [&_button]:font-normal [&_button]:hover:text-indigo-600] space-y-1"
      >
        {{yield}}
      </ul>
    </li>
  </template>
}

/**
 * Represents a single item within a sidebar, which is a link that can optionally display an icon.
 *
 * @example
 * <SidebarItem @route="authenticated.people.index" @icon="team" @label="People" />
 *
 * @template
 * Renders as a list item containing a link, potentially with an icon, styled according to its
 * active or hover state. The `active` class styling is conditional based on Ember's `LinkTo`
 * component.
 *
 * @interface
 * Args:
 *  - route: string - The route to link to.
 *  - icon?: Icons - Optional icon to display alongside the label.
 *  - label: string - Text label for the link.
 *  - isSubmenu?: boolean - Optional flag to indicate if this item is part of a submenu (affects styling).
 */

interface SidebarItemSignature {
  Args: {
    route: string;
    icon?: unknown;
    label: string;
    isSubmenu?: boolean;
  };
  Element: HTMLButtonElement;
}

/**
 * Top-level component for rendering a sidebar navigation structure, containing various navigation items,
 * possibly grouped.
 *
 * @template
 * A structured list of `SidebarItem` and `SidebarItemGroup` components representing different navigation
 * paths and functionalities of an application.
 *
 * @example
 * <Sidebar>
 *   <SidebarItem @route="authenticated.dashboard.index" @label="Dashboard" />
 *   <SidebarItemGroup @label="cog" @label="Settings">
 *     <SidebarItem @route="settings.profile" @label="Profile" />
 *   </SidebarItemGroup>
 * </Sidebar>
 */

export class SidebarItem extends Component<SidebarItemSignature> {
  @service declare router: RouterService;

  transitionToRoute = (route: any, event: MouseEvent) => {
    // Fallback for browsers that don't support this API:
    if (!document.startViewTransition) {
      this.router.transitionTo(route);
      return;
    }

    function setActiveItem(element: HTMLElement) {
      const allLinks = document.querySelectorAll('#nav');
      allLinks.forEach((link) => link.classList.remove('animation-appear'));
      const navElement = element.closest('#nav');
      if (navElement) {
        navElement.classList.add('animation-appear');
      }
    }
    // With a transition:
    document.startViewTransition(() => {
      setActiveItem(event.target as HTMLElement);
      this.router.transitionTo(route);
    });
  };

  <template>
    {{! TODO: Should element type be configurable?}}
    <li>
      <button
        type="button"
        id="nav"
        {{on "click" (fn this.transitionToRoute @route)}}
        class="w-full group -mx-2 flex gap-x-1 rounded-md p-2 text-sm font-semibold text-white text-left hover:bg-gray-50 hover:text-midnight"
        ...attributes
      >
        {{#if @icon}}
          {{! @glint-expect-error: need types on heroicons}}
          <@icon
            class="[&.active]:text-midnight [&.active]:font-bold h-5 w-5 shrink-0 hover:text-midnight"
          />
        {{/if}}
        <p>
          {{@label}}
        </p>
      </button>
    </li>
  </template>
}

interface AppSidebarSignature {
  Args: {
    isTaOnly: boolean;
    showTuitionAssistance: boolean;
    isParticipant: boolean;
    showPslf: boolean;
    showSyf: boolean;
    showIdr: boolean;
    showSlr: boolean;
    isAdmin: boolean;
    isOwner: boolean;
    isPslfAdmin: boolean;
    isTasApprover: boolean;
    isTasPartnerPaymentsEnabled: boolean;
    isEmployeeAdmin: boolean;
  };
}

// TODO: Rename once old one is gone.
const AppSidebar: TOC<AppSidebarSignature> = <template>
  <div class="flex h-16 shrink-0 items-center">
    <span class="font-semibold text-2xl mt-4 mb-2 text-white">
      {{t "sidebar.tio"}}
    </span>
  </div>
  <nav class="flex flex-1 flex-col">
    <ul role="list" class="flex flex-1 flex-col gap-y-7">
      <li>
        <ul role="list" class="-mx-2 space-y-1">
          <SidebarItem @route="authenticated.dashboard" @icon={{Home}} @label="Home" />
          {{#if (or @isAdmin @isOwner)}}
            <SidebarItem
              @route="authenticated.admin.reports"
              @icon={{TableCells}}
              @label={{t "common.reporting.default"}}
              data-test-sidebar-admin-reports
            />
            <SidebarItem
              @route="authenticated.admin.employees"
              @icon={{UserCircle}}
              @label={{t "sidebar.admin_employees"}}
              data-test-sidebar-admin-employees
            />
            <SidebarItem
              @route="authenticated.admin.eligibility-files"
              @icon={{ArrowUpTray}}
              @label={{t "sidebar.eligibility_files"}}
              data-test-sidebar-admin-eligibility-files
            />
          {{/if}}
          {{#if (or @isAdmin (and @isOwner @showPslf) @isPslfAdmin)}}
            <SidebarItem
              @route="authenticated.admin.pslf.forms"
              @icon={{Newspaper}}
              @label={{t "sidebar.admin_pslf"}}
              data-test-sidebar-admin-pslf
            />
          {{/if}}
          {{#if (or @isAdmin @isOwner @isTasApprover)}}
            <SidebarItemGroup @icon={{UserCircle}} @label={{t "sidebar.admin_tuition_assistance"}}>
              {{#if (and @isTasPartnerPaymentsEnabled (or @isAdmin @isOwner @isEmployeeAdmin))}}
                <SidebarItem
                  @route="authenticated.admin.tuition-assistance.payments"
                  @label={{t "sidebar.payments"}}
                  data-test-sidebar-admin-tuition-assistance-payments
                />
              {{/if}}
              <SidebarItem
                @route="authenticated.admin.tuition-assistance.applications.index"
                @label={{t "tuition_assistance.applications.default"}}
                data-test-sidebar-admin-tuition-assistance-applications
              />
              {{! TODO: Eventually this should be a single link that goes to reporting index page with links to each page }}
              {{#if (or @isAdmin @isOwner @isEmployeeAdmin)}}
                <SidebarItem
                  @route="authenticated.admin.reporting.index"
                  @label={{t "reports.all_reports_link"}}
                  data-test-sidebar-admin-tuition-assistance-reports
                />
              {{/if}}
            </SidebarItemGroup>
          {{/if}}
          {{#if (or @isTasApprover @isPslfAdmin @isAdmin @isOwner)}}
            <li><Divider /></li>
          {{/if}}
          {{#if (and @isTaOnly @showTuitionAssistance)}}
            <SidebarItem
              @route="authenticated.tas.dashboard"
              @icon={{AcademicCap}}
              @label="Tuition Assistance"
              data-test-sidebar-employee-tuition-assistance-dashboard
            />
          {{/if}}

          {{#if @isParticipant}}
            {{#if @showSlr}}
              <SidebarItemGroup @icon={{UserCircle}} @label="Account Activity">
                <SidebarItem
                  @route="authenticated.account-activity.accounts"
                  @label="Loan Summary"
                  data-test-sidebar-employee-student-loan-summary
                />
                <SidebarItem
                  @route="authenticated.slr.dashboard"
                  @label="Employer Contributions"
                  data-test-sidebar-employee-employer-contributions
                />
              </SidebarItemGroup>
            {{else}}
              <SidebarItem
                @route="authenticated.account-activity.accounts"
                @icon={{UserCircle}}
                @label="Loan Summary"
                data-test-sidebar-employee-student-loan-summary
              />
            {{/if}}
            {{#if @showPslf}}
              <SidebarItemGroup @icon={{Lifebuoy}} @label="Loan Forgiveness">
                <SidebarItem
                  @route="authenticated.pslf.dashboard"
                  @label="PSLF"
                  data-test-sidebar-pslf
                />
                <SidebarItem
                  @route="authenticated.pslf.my-documents"
                  @label="My Forms and Documents"
                  data-test-sidebar-employee-pslf-my-documents
                />
              </SidebarItemGroup>
            {{/if}}
            {{#if @showTuitionAssistance}}
              <SidebarItem
                @route="authenticated.tas.dashboard"
                @icon={{AcademicCap}}
                @label="Tuition Assistance"
                data-test-sidebar-employee-primary-tuition-assistance
              />
            {{/if}}
            {{#if @showSyf}}
              <SidebarItem
                @route="authenticated.syf.dashboard"
                @icon={{RocketLaunch}}
                @label="Secure Your Future"
                data-test-sidebar-employee-primary-secure-your-future
              />
            {{/if}}
            {{#if @showIdr}}
              <SidebarItem
                @icon={{CurrencyDollar}}
                @route="authenticated.idr.dashboard"
                @label="Income-Driven Repayment"
                data-test-sidebar-employee-primary-income-driven-repayment
              />
            {{/if}}

            <SidebarItemGroup
              @icon={{CurrencyDollar}}
              @label="Repaying Debt"
              data-test-sidebar-employee-primary-repaying-student-debt
            >
              <SidebarItem
                @route="authenticated.repaying-student-debt.askjeni"
                @label="Loan Coaches"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.loan-forgiveness.index"
                @label="Loan Forgiveness"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.repayment-strategy-finder.dashboard"
                @label="Strategy Finder"
              />
              <SidebarItem
                @route="authenticated.repaying-student-debt.repayment-options"
                @label="Consolidation & Refinancing"
              />
            </SidebarItemGroup>
            <SidebarItemGroup @icon={{BookOpen}} @label="Questions">
              <SidebarItem
                @route="authenticated.questions-you-have.paying-for-college"
                @label="Types of Student Loans"
              />
              <SidebarItem
                @route="authenticated.questions-you-have.repayment-plans"
                @label="Repayment Plans"
              />
              <SidebarItem
                @route="authenticated.questions-you-have.glossary"
                @label="Loan Glossary"
              />
            </SidebarItemGroup>
          {{/if}}
        </ul>
      </li>
      <li class="mt-auto">
        <ul>
          {{! Shows pinning to bottom of sidebar }}
          <SidebarItem
            class="mt-auto"
            @route="logout"
            @icon={{ArrowLeftOnRectangle}}
            @label="Logout"
          />
        </ul>
      </li>
    </ul>
  </nav>
</template>;
