import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { eq } from 'tio-ui/utilities';
import { fn, hash } from '@ember/helper';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FileUploader from 'tio-common/components/file-uploader';
import FormCheckbox from '@frontile/forms-legacy/components/form-checkbox';
import FormInput from '@frontile/forms-legacy/components/form-input';
import inputmask from 'tio-common/modifiers/inputmask';
import TioClickableText from 'tio-common/components/tio/clickable-text';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import type AccountModel from 'tio-common/models/account';
import type DigitalAssetModel from 'tio-common/models/digital-asset';
import type DigitalAssetsService from 'tio-common/services/digital-assets';
import type MatchAssetModel from 'tio-common/models/match-asset';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';

interface SyfPaymentsNewRouteSignature {
  Args: {
    // this template is rendered on a child route of syf without any intervening route
    // definitions so it shares a model with its eventual parent; convenient ember convention
    model: {
      matchParticipant: MatchParticipantModel;
      accounts: Array<AccountModel>;
    };
  };
  Blocks: {
    default: [];
  };
}

type AccountTransactionArgs = {
  amount?: number;
  transactionDate?: string;
};

export default class SyfPaymentsNewRoute extends Component<SyfPaymentsNewRouteSignature> {
  @service declare digitalAssets: DigitalAssetsService;
  // NB: can remove this if the bulk account transaction commit functionality
  // gets moved to a service
  @service declare store: typeof Store;
  @service declare router: RouterService;

  @tracked accountTransactionsData: Array<AccountTransactionArgs> = [
    { amount: undefined, transactionDate: undefined },
  ];
  @tracked assets: Array<MatchAssetModel> = [];
  @tracked fileUploadDetails: Array<DigitalAssetModel['details']> = [];
  @tracked loanPaymentsConfirmed = false;
  @tracked assetLoading = false;

  get noLinkedAccounts() {
    const { accounts } = this.args.model;
    return accounts.length === 0;
  }

  get accountsInRelink() {
    const { accounts } = this.args.model;
    return accounts.some((account) => account.inRelink);
  }

  @action
  addAccountTransaction(): void {
    const newTransactionData = { amount: undefined, transactionDate: undefined };
    this.accountTransactionsData = [...this.accountTransactionsData, newTransactionData];
  }

  @action
  removeAccountTransaction(targetIdx: number): void {
    this.accountTransactionsData = this.accountTransactionsData.reduce((acc, cur, idx) => {
      if (idx === targetIdx) return acc;
      return [...acc, cur];
    }, [] as Array<AccountTransactionArgs>);
  }

  @action setTransactionAmount(
    transactionIndex: AccountTransactionArgs,
    _value: string | number,
    event: Event
  ) {
    // @ts-expect-error: input mask needs types
    const unmaskedValue = event.target.inputmask.unmaskedvalue();
    const newTransactionData = [...this.accountTransactionsData];
    // @ts-expect-error: bug? incorrect types? who knows
    newTransactionData[transactionIndex].amount = unmaskedValue * 100;
    this.accountTransactionsData = newTransactionData;
  }

  @action setTransactionDate(transactionIndex: AccountTransactionArgs, value: string) {
    const newTransactionData = [...this.accountTransactionsData];
    // @ts-expect-error: bug? incorrect types? who knows
    newTransactionData[transactionIndex].transactionDate = value;
    this.accountTransactionsData = newTransactionData;
  }

  @action async didUploadFile(details: DigitalAssetModel['details']): Promise<void> {
    this.assetLoading = true;
    const asset = this.digitalAssets.createAssetFromS3Details('match-asset', details);
    try {
      await asset.save();
      this.assets = [...this.assets, asset];
    } finally {
      this.assetLoading = false;
    }
  }

  @action
  toggleLoanPaymentsConfirmed(): void {
    this.loanPaymentsConfirmed = !this.loanPaymentsConfirmed;
  }

  // see description in https://tuitionio.atlassian.net/browse/DEV-82; spec seems
  // like a back navigation
  @action
  navigateBack() {
    window.history.back();
  }

  get submitDisabled() {
    if (!this.loanPaymentsConfirmed) return true;
    if (this.assetLoading) return true;
    if (!this.assets.length) return true;
    const transactionDataIncomplete = this.accountTransactionsData.some((transactionData) => {
      if (!transactionData.amount || !transactionData.transactionDate) return true;
      return false;
    });
    if (transactionDataIncomplete) return true;
    return false;
  }

  @action
  async onSubmit(): Promise<void> {
    // NB: this logic could probably move to a service if any other views end up
    // needing to do something similar with an array of account transactions. it
    // may also be a good idea to standardize our approach to bulk commits via the
    // ember data store
    const jobs = this.accountTransactionsData.map(async (data) => {
      const transaction = await this.store.createRecord('account-transaction', {
        amount: data.amount,
        transactionDate: data.transactionDate,
        transactionType: 'TransactionType.PAYMENT',
        category: 'TransactionCategory.DEBIT',
        person: this.args.model.matchParticipant.person,
        matchAssets: this.assets,
      });
      transaction.save();
    });

    // separate calls here feels ugly, but ember data doesn't really support bulk
    // commits; at least the requests can happen in parallel rather than in sequence
    Promise.all(jobs).then(() => {
      this.router.transitionTo('authenticated.syf.dashboard');
    });
  }

  <template>
    <h2 class="text-midnight text-lg font-semibold mt-4 mb-4">
      {{t "syf.payments.new.header"}}
    </h2>
    {{#if this.noLinkedAccounts}}
      <p class="mb-8">
        <LinkTo
          @route="authenticated.observability.upload"
          @query={{hash source="syf"}}
          class="text-ocean-600 underline"
        >
          {{t "syf.payments.new.unlinked_anchor_text"}}
        </LinkTo>
        {{t "syf.payments.new.unlinked_info"}}
      </p>
    {{else if this.accountsInRelink}}
      <p class="mb-8">
        {{t "syf.payments.new.relink_prefix"}}
        <LinkTo
          @route="authenticated.observability.upload"
          @query={{hash source="syf"}}
          class="text-ocean-600 underline"
        >
          {{t "syf.payments.new.relink_anchor_text"}}
        </LinkTo>
        {{t "syf.payments.new.relink_info"}}
      </p>
    {{else}}
      <p class="mb-8">{{t "syf.payments.new.linked_info"}}</p>
    {{/if}}
    <div class="grid md:grid-cols-4">
      <div class="col-span-4 md:col-span-2 mb-8">
        <label class="font-semibold text-lg mb-4 text-gray-700 inline-block">
          {{t "syf.payments.new.form.payments.section_header"}}
        </label>
        {{#each this.accountTransactionsData as |transactionData index|}}
          <div class="grid grid-cols-10 items-center mb-8">
            <div class="flex col-span-9">
              <FormInput
                data-legacy-input
                @label={{t "syf.payments.new.form.payments.amount_label"}}
                @value="{{transactionData.amount}}"
                {{! @glint-expect-error: might be a bug? not sure about the arg list}}
                @onInput={{fn this.setTransactionAmount index}}
                @containerClass="mr-2"
                autocomplete="off"
                {{inputmask alias="currency" prefix="$" unmaskAsNumber=true digits="2"}}
              />
              <FormInput
                data-legacy-input
                @label={{t "syf.payments.new.form.payments.date_label"}}
                @value={{transactionData.transactionDate}}
                {{! @glint-expect-error: might be a bug? not sure about the arg list}}
                @onChange={{fn this.setTransactionDate index}}
                @type="date"
              />
            </div>
            {{#unless (eq index 0)}}
              <div class="col-span-1">
                <Button
                  @appearance="outlined"
                  @intent="danger"
                  {{on "click" (fn this.removeAccountTransaction index)}}
                >
                  <Pencil class="w-4" />
                </Button>
              </div>
            {{/unless}}
          </div>
        {{/each}}
        <TioClickableText
          @icon="add"
          @iconLeft={{true}}
          @outlined={{true}}
          @onClick={{this.addAccountTransaction}}
        >
          {{t "syf.payments.new.form.payments.add_new"}}
        </TioClickableText>
      </div>
      <div class="col-span-4 md:col-span-2">
        <label class="font-semibold text-lg mb-4 text-gray-700 inline-block">
          {{t "syf.payments.new.form.statements.section_header"}}
        </label>
        <FileUploader @didUploadFile={{this.didUploadFile}} @accept=".pdf" />
        <p class="text-small">
          {{t "syf.payments.new.form.statements.hint"}}
        </p>
        {{#each this.assets as |matchAsset|}}
          <div class="my-2">{{matchAsset.name}}</div>
        {{/each}}
      </div>
      <div class="mb-8 col-span-4 md:col-start-2 md:col-span-2">
        <FormCheckbox
          data-legacy-input
          @onChange={{this.toggleLoanPaymentsConfirmed}}
          @checked={{this.loanPaymentsConfirmed}}
          class="cursor-pointer"
        >
          {{@model.matchParticipant.matchPlan.ensureVerifyAccountStatement}}
        </FormCheckbox>
      </div>
      <div class="flex justify-around col-span-4 md:col-start-2 md:col-span-2">
        <Button @appearance="outlined" class="w-1/4" {{on "click" this.navigateBack}}>
          {{t "syf.payments.new.form.cancel"}}
        </Button>
        <Button disabled={{this.submitDisabled}} {{on "click" this.onSubmit}} class="w-1/4">
          {{t "syf.payments.new.form.submit"}}
        </Button>
      </div>
    </div>
  </template>
}
